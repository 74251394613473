import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Subheading } from 'component-library/components/typography/Subheading';
import { colors } from 'component-library/styles/colors';
import { device } from 'component-library/styles/device';
import React, { FC } from 'react';
import styled from 'styled-components';

interface ProfileWrapperProps {
  backgroundColor: string;
}

const ProfileWrapper = styled(Flex)<ProfileWrapperProps>`
  flex-direction: column;
  border-radius: 24px;
  background: ${({ backgroundColor }) => backgroundColor};
  border: 1px solid ${colors.navyBlue};
  margin-bottom: 20px;
  overflow: hidden;
  transform: translateZ(0);

  ${`@media ${device.mdOnly}`} {
    flex-direction: row;
    > div:first-child {
      flex-basis: 40%;
    }
  }
`;

const Text = styled.div`
  padding: 24px;
  ${`@media ${device.mdOnly}`} {
    flex-basis: 60%;
  }
`;

interface ProfileProps extends ProfileWrapperProps {
  name: string;
  role: string;
  bio?: string;
  image: React.ReactNode;
}

export const ProfileSmall: FC<ProfileProps> = ({
  name,
  role,
  bio,
  image,
  backgroundColor,
}) => (
  <ProfileWrapper backgroundColor={backgroundColor}>
    {image}
    <Text>
      <Subheading marginBottom={8} variant={1} medium>
        {name}
      </Subheading>
      <Body variant={3} marginBottom={12}>
        {role}
      </Body>
      {bio && <Body variant={3}>{bio}</Body>}
    </Text>
  </ProfileWrapper>
);
