import {
  BeGenuine,
  DistillTheMessage,
  InvestInGrowth,
  TakeAction,
} from 'component-library/components/icons';
import { Flex, FlexProps } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Subheading } from 'component-library/components/typography/Subheading';
import { colors } from 'component-library/styles/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

const StyledFlex = styled(Flex)`
  background: ${colors.greenLight};
  border-radius: 24px;
  padding: 40px;
`;

export enum ValueVariant {
  BeGenuine = 'BeGenuine',
  InvestInGrowth = 'InvestInGrowth',
  DistillTheMessage = 'DistillTheMessage',
  TakeAction = 'TakeAction',
}

const options = {
  [ValueVariant.BeGenuine]: {
    Icon: BeGenuine,
    heading: 'Be Genuine',
    body: 'Keep empathy and compassion central to everything we do.',
  },
  [ValueVariant.InvestInGrowth]: {
    Icon: InvestInGrowth,
    heading: 'Invest in Growth',
    body: 'Our individuals reach higher to elevate the entire team.',
  },
  [ValueVariant.DistillTheMessage]: {
    Icon: DistillTheMessage,
    heading: 'Distill the Message',
    body: 'Be clear, concise, and direct in everything we communicate. ',
  },
  [ValueVariant.TakeAction]: {
    Icon: TakeAction,
    heading: 'Take Action',
    body: 'Quickly take ownership to find solutions that make a difference.',
  },
};

interface ValueProps extends FlexProps {
  valueVariant: ValueVariant;
}

export const Value: FC<ValueProps> = ({ valueVariant, ...rest }) => {
  const { Icon, heading, body } = options[valueVariant];

  return (
    <StyledFlex column {...rest}>
      <Icon />
      <Subheading variant={2} marginBottom={12} marginTop={64}>
        {heading}
      </Subheading>
      <Body variant={3}>{body}</Body>
    </StyledFlex>
  );
};
