import { Flex } from 'component-library/components/layout/Flex';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

import { ProfileSmall } from '../shared/ProfileSmall';
import { WrapperLarge } from '../shared/WrapperLarge';
import { advisors, heading } from './content';

const OurAdvisorsWrapper = styled(Flex)`
  gap: 40px;
`;

export const OurAdvisorsLarge = () => (
  <WrapperLarge maxWidth={1569} spacing={40}>
    <ResponsiveHeading variant={3} marginBottom={48}>
      {heading}
    </ResponsiveHeading>
    <OurAdvisorsWrapper>
      <ProfileSmall
        {...advisors['mark']}
        image={<StaticImage src="../images/mark.webp" alt="mark-casady" />}
      />
      <ProfileSmall
        {...advisors['cindy']}
        image={<StaticImage src="../images/cindy.webp" alt="cindy-gordon" />}
      />
      <ProfileSmall
        {...advisors['stephany']}
        image={
          <StaticImage
            src="../images/stephany.webp"
            alt="stephany-kirkpatrick"
          />
        }
      />
    </OurAdvisorsWrapper>
  </WrapperLarge>
);
