import { colors } from 'component-library/styles/colors';

export const heading = 'Advisory team';
export const advisors = {
  mark: {
    name: 'Mark Casady',
    role: 'GP Vestigo Ventures, Former CEO of LPL Financial',
    backgroundColor: colors.green,
  },
  cindy: {
    name: 'Cindy Gordon',
    role: 'CHRO, Former Chief People Officer at Policygenius',
    backgroundColor: colors.green,
  },
  stephany: {
    name: 'Stephany Kirkpatrick',
    role: 'CEO at Orum, Former GM at Learnvest',
    backgroundColor: colors.green,
  },
};
