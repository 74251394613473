import React from 'react';

import { WhatWeAreLarge } from './WhatWeAreLarge';
import { WhatWeAreSmall } from './WhatWeAreSmall';

export const WhatWeAre = () => (
  <>
    <WhatWeAreSmall />
    <WhatWeAreLarge />
  </>
);
