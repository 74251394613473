import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

import { ProfileSmall } from '../shared/ProfileSmall';
import { founders, heading } from './content';

const MeetTheFoundersWrapper = styled(ShowOn)`
  padding: 0 20px 88px;
`;

export const MeetTheFoundersSmall = () => (
  <MeetTheFoundersWrapper screens={[Screen.Sm, Screen.Md]}>
    <Heading variant={4} marginBottom={40}>
      {heading}
    </Heading>
    <ProfileSmall
      {...founders['tyler']}
      image={<StaticImage src="../images/tyler.webp" alt="tyler-end-ceo" />}
    />
    <ProfileSmall
      {...founders['ian']}
      image={<StaticImage src="../images/ian.webp" alt="ian-yamey-cto" />}
    />
  </MeetTheFoundersWrapper>
);
