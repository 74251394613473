export const attributes = [
  {
    heading: 'Companions',
    body: 'We provide dependable support today, tomorrow, and beyond.',
  },
  {
    heading: 'Guides',
    body:
      'We use our expertise to meet you where you’re at and lead the way towards a brighter future.',
  },
  {
    heading: 'Problem Solvers',
    body: 'We find smart ways to overcome complex challenges.',
  },
];
