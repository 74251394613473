import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import React from 'react';
import styled from 'styled-components';

import { heading } from './content';
import { Value, ValueVariant } from './Value';

const ValuesWrapper = styled(ShowOn)`
  border-radius: 32px;
  background: ${colors.teal};
  padding: 48px 20px 20px;
  margin-bottom: 32px;
`;

export const ValuesSmall = () => (
  <ValuesWrapper screens={[Screen.Sm, Screen.Md]}>
    <Heading variant={4} marginBottom={40}>
      {heading}
    </Heading>
    <Value valueVariant={ValueVariant.BeGenuine} marginBottom={20} />
    <Value valueVariant={ValueVariant.InvestInGrowth} marginBottom={20} />
    <Value valueVariant={ValueVariant.DistillTheMessage} marginBottom={20} />
    <Value valueVariant={ValueVariant.TakeAction} />
  </ValuesWrapper>
);
