import React from 'react';

import { OurAdvisorsLarge } from './OurAdvisorsLarge';
import { OurAdvisorsSmall } from './OurAdvisorsSmall';

export const OurAdvisors = () => (
  <>
    <OurAdvisorsSmall />
    <OurAdvisorsLarge />
  </>
);
