import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Subheading } from 'component-library/components/typography/Subheading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

import { attributes } from './content';

const WhatWeAreWrapper = styled(ShowOn)`
  background: ${colors.peach};
  border-radius: 32px;
  overflow: hidden;
  margin-bottom: 32px;
`;

const Text = styled.div`
  padding: 40px 20px 40px;
`;

const Image = styled.div`
  max-width: 400px;
`;

export const WhatWeAreSmall = () => (
  <WhatWeAreWrapper screens={[Screen.Sm, Screen.Md]}>
    <Image>
      <StaticImage
        src="../images/founders-2.webp"
        alt="retirable-founders-smiling"
      />
    </Image>
    <Text>
      {attributes.map(({ heading, body }, idx) => (
        <Flex column marginBottom={40} key={idx}>
          <Subheading variant={1} marginBottom={8}>
            {heading}
          </Subheading>
          <Body variant={2}>{body}</Body>
        </Flex>
      ))}
    </Text>
  </WhatWeAreWrapper>
);
