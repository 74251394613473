import { Flex } from 'component-library/components/layout/Flex';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import { StaticImage } from 'gatsby-plugin-image';
import React, { useState } from 'react';
import styled from 'styled-components';

import { ProfileLarge } from '../shared/ProfileLarge';
import { WrapperLarge } from '../shared/WrapperLarge';
import { founders, heading } from './content';

type BioName = 'tyler' | 'ian';

const MeetTheFoundersWrapper = styled(Flex)`
  gap: 40px;
  height: 63vh;
`;

const showFounderProfile = (bioName: BioName | null, founder: BioName) =>
  !bioName || bioName === founder;

export const MeetTheFoundersLarge = () => {
  const [bioName, updateBioName] = useState<BioName | null>(null);

  const onClick = (advisor: BioName) => {
    if (advisor === bioName) {
      updateBioName(null);
    } else {
      updateBioName(advisor);
    }
  };

  return (
    <WrapperLarge maxWidth={1569} spacing={40}>
      <ResponsiveHeading variant={3} marginBottom={48}>
        {heading}
      </ResponsiveHeading>
      <MeetTheFoundersWrapper>
        {showFounderProfile(bioName, 'tyler') && (
          <ProfileLarge
            {...founders['tyler']}
            image={<StaticImage src="../images/tyler.webp" alt="tyler-end" />}
            onClick={() => onClick('tyler')}
            showBio={bioName === 'tyler'}
          />
        )}
        {showFounderProfile(bioName, 'ian') && (
          <ProfileLarge
            {...founders['ian']}
            image={<StaticImage src="../images/ian.webp" alt="ian-yamey" />}
            onClick={() => onClick('ian')}
            showBio={bioName === 'ian'}
          />
        )}
      </MeetTheFoundersWrapper>
    </WrapperLarge>
  );
};
