import { Circle } from 'component-library/components/icons';
import { Icon, IconName } from 'component-library/components/icons/Icon';
import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Subheading } from 'component-library/components/typography/Subheading';
import { colors } from 'component-library/styles/colors';
import React, { FC } from 'react';
import styled from 'styled-components';

interface ProfileProps {
  name: string;
  role: string;
  bio: string;
  image: React.ReactNode;
  onClick: () => void;
  showBio: boolean;
}

const ProfileWrapper = styled(Flex)<Pick<ProfileProps, 'showBio'>>`
  flex-direction: ${({ showBio }) => (showBio ? 'row' : 'column')};
  border-radius: 24px;
  background: ${colors.peach};
  border: 1px solid ${colors.navyBlue};
  cursor: pointer;
  flex-basis: ${({ showBio }) => (showBio ? '100%' : '33%')};
  overflow: hidden;
  transform: translateZ(0);

  ${({ showBio }) =>
    !showBio &&
    `img:hover {
        transition: 0.25s all ease-in-out;
        transform: scale(1.05);
    }`};
`;

const Text = styled.div`
  padding: 32px;
`;

export const ProfileLarge: FC<ProfileProps> = ({
  name,
  role,
  bio,
  image,
  onClick,
  showBio,
}) => (
  <ProfileWrapper onClick={onClick} showBio={showBio}>
    {image}
    <Text>
      <Flex justifyContent="space-between">
        <Flex column>
          <Subheading marginBottom={8} variant={1} medium>
            {name}
          </Subheading>
          <Body variant={3} marginBottom={12}>
            {role}
          </Body>
        </Flex>
        <Flex>
          {showBio && (
            <Circle>
              <Icon name={IconName.Xmark} />
            </Circle>
          )}
        </Flex>
      </Flex>
      {showBio && <Body variant={3}>{bio}</Body>}
    </Text>
  </ProfileWrapper>
);
