import React from 'react';

import { MeetTheFoundersLarge } from './MeetTheFoundersLarge';
import { MeetTheFoundersSmall } from './MeetTheFoundersSmall';

export const MeetTheFounders = () => (
  <>
    <MeetTheFoundersSmall />
    <MeetTheFoundersLarge />
  </>
);
