import React from 'react';

import { ValuesLarge } from './ValuesLarge';
import { ValuesSmall } from './ValuesSmall';

export const Values = () => (
  <>
    <ValuesSmall />
    <ValuesLarge />
  </>
);
