import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

import { body, heading } from './content';

const OurStoryWrapper = styled(ShowOn)`
  padding: 0 20px 88px;
`;

const Image = styled.div`
  max-width: 400px;
`;

export const OurStorySmall = () => (
  <OurStoryWrapper screens={[Screen.Sm, Screen.Md]}>
    <Heading marginBottom={24} variant={4}>
      {heading}
    </Heading>
    <Image>
      <StaticImage src="../images/founders-1.webp" alt="retirable-founders" />
    </Image>
    {body.map((paragraph, idx) => (
      <Body variant={3} marginTop={32} key={idx}>
        {paragraph}
      </Body>
    ))}
  </OurStoryWrapper>
);
