import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

import { ProfileSmall } from '../shared/ProfileSmall';
import { advisors, heading } from './content';

const OurAdvisorsWrapper = styled(ShowOn)`
  padding: 0 20px 80px;
`;

export const OurAdvisorsSmall = () => (
  <OurAdvisorsWrapper screens={[Screen.Sm, Screen.Md]}>
    <Heading variant={4} marginBottom={40}>
      {heading}
    </Heading>
    <ProfileSmall
      {...advisors['mark']}
      image={<StaticImage src="../images/mark.webp" alt="mark-casady" />}
    />
    <ProfileSmall
      {...advisors['cindy']}
      image={<StaticImage src="../images/cindy.webp" alt="cindy-gordon" />}
    />
    <ProfileSmall
      {...advisors['stephany']}
      image={
        <StaticImage src="../images/stephany.webp" alt="stephany-kirkpatrick" />
      }
    />
  </OurAdvisorsWrapper>
);
