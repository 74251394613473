import { Flex } from 'component-library/components/layout/Flex';
import { Body } from 'component-library/components/typography/Body';
import { Subheading } from 'component-library/components/typography/Subheading';
import { colors } from 'component-library/styles/colors';
import { StaticImage } from 'gatsby-plugin-image';
import React from 'react';
import styled from 'styled-components';

import { WrapperLarge } from '../shared/WrapperLarge';
import { attributes } from './content';

const InnerWrapper = styled(Flex)`
  background: ${colors.peach};
  border: 1px solid ${colors.navyBlue};
  border-radius: 24px;
  overflow: hidden;
  > div:first-child {
    flex-basis: 57%;
  }
`;

const Text = styled.div`
  max-width: 548px;
  padding: 40px;
  margin: auto;
  flex-basis: 43%;
`;

export const WhatWeAreLarge = () => (
  <WrapperLarge maxWidth={1569} spacing={120}>
    <InnerWrapper>
      <StaticImage
        src="../images/founders-2.webp"
        alt="retirable-founders-smiling"
      />
      <Text>
        {attributes.map(({ heading, body }, idx) => (
          <Flex column marginBottom={56} key={idx}>
            <Subheading variant={1} marginBottom={8}>
              {heading}
            </Subheading>
            <Body variant={2}>{body}</Body>
          </Flex>
        ))}
      </Text>
    </InnerWrapper>
  </WrapperLarge>
);
