import { Badge } from 'component-library/components/badge/Badge';
import { ResponsiveBody } from 'component-library/components/typography/ResponsiveBody';
import { ResponsiveHeading } from 'component-library/components/typography/ResponsiveHeading';
import React from 'react';
import styled from 'styled-components';

import { WrapperLarge } from '../shared/WrapperLarge';
import { badge, body, heading } from './content';

const HeroWrapper = styled.div`
  padding-top: 130px;
`;

export const HeroLarge = () => (
  <WrapperLarge maxWidth={1569} spacing={90}>
    <HeroWrapper>
      <Badge marginBottom={32}>{badge}</Badge>
      <ResponsiveHeading maxWidth={1230} variant={2} marginBottom={24}>
        {heading}
      </ResponsiveHeading>
      <ResponsiveBody variant={1} maxWidth={975}>
        {body}
      </ResponsiveBody>
    </HeroWrapper>
  </WrapperLarge>
);
