import Layout from 'common/layouts/Layout';
import { Navigation } from 'component-library/components/navigation/Navigation';
import { PageWrapper } from 'component-library/components/wrappers/PageWrapper';
import { SEO } from 'components/SEO';
import {
  Hero,
  MeetTheFounders,
  OurAdvisors,
  OurStory,
  Values,
  WhatWeAre,
} from 'page-components/about-us';
import { BottomCta } from 'page-components/shared';
import { Footer } from 'page-components/shared/footer';
import React, { FC } from 'react';

interface AboutUsProps {}

const AboutUs: FC<AboutUsProps> = () => (
  <Layout>
    <Navigation />
    <PageWrapper background="linear-gradient(180deg, #DCF1E8 0%, rgba(220, 241, 232, 0.2) 72.47%, #FFEDE0 81.54%, #DBF0E7 100%)">
      <Hero />
      <OurStory />
      <MeetTheFounders />
      <OurAdvisors />
      <Values />
      <WhatWeAre />
      <BottomCta
        heading="Let us make your next chapter the best one yet."
        body="From greater peace of mind to ongoing care, we're ready to lend a hand."
      />
      <Footer />
    </PageWrapper>
  </Layout>
);

export default AboutUs;

export const Head = () => (
  <SEO
    title="About Us | Retirable"
    description="Retirable was founded on a mission to empower a confident, worry-free retirement for everyone."
    imagePath="about-us-featured.png"
  />
);
