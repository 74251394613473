import { Badge } from 'component-library/components/badge/Badge';
import { Body } from 'component-library/components/typography/Body';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import React from 'react';
import styled from 'styled-components';

import { badge, body, heading } from './content';

const HeroWrapper = styled(ShowOn)`
  padding: 48px 20px 88px;
`;

export const HeroSmall = () => (
  <HeroWrapper screens={[Screen.Sm, Screen.Md]}>
    <Badge marginBottom={24}>{badge}</Badge>
    <Heading marginBottom={16} variant={4}>
      {heading}
    </Heading>
    <Body variant={3}>{body}</Body>
  </HeroWrapper>
);
