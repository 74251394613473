import React from 'react';

import { OurStoryLarge } from './OurStoryLarge';
import { OurStorySmall } from './OurStorySmall';

export const OurStory = () => (
  <>
    <OurStorySmall />
    <OurStoryLarge />
  </>
);
