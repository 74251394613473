import { Flex } from 'component-library/components/layout/Flex';
import { Heading } from 'component-library/components/typography/Heading';
import { Screen, ShowOn } from 'component-library/components/wrappers/ShowOn';
import { colors } from 'component-library/styles/colors';
import React from 'react';
import styled from 'styled-components';

import { heading } from './content';
import { Value, ValueVariant } from './Value';

const ValuesWrapper = styled(ShowOn)`
  border-radius: 32px;
  background: ${colors.teal};
  margin: 40px;
`;

const InnerWrapper = styled.div`
  padding: 80px 40px;
  max-width: 1568px;
  margin: 0 auto;
`;

export const ValuesLarge = () => (
  <ValuesWrapper screens={[Screen.Lg, Screen.Xl, Screen.Xxl]}>
    <InnerWrapper>
      <Heading variant={2} marginBottom={72}>
        {heading}
      </Heading>
      <Flex>
        <Value valueVariant={ValueVariant.BeGenuine} marginRight={20} />
        <Value valueVariant={ValueVariant.InvestInGrowth} marginRight={20} />
        <Value valueVariant={ValueVariant.DistillTheMessage} marginRight={20} />
        <Value valueVariant={ValueVariant.TakeAction} />
      </Flex>
    </InnerWrapper>
  </ValuesWrapper>
);
