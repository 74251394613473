import { colors } from 'component-library/styles/colors';

export const heading = 'Meet the founders';
export const founders = {
  tyler: {
    name: 'Tyler End',
    role: 'CFP®, CEO',
    bio:
      'As a retirement income specialist at Northwestern Mutual, Tyler worked hands-on to help people define their goals, achieve financial independence, and enter retirement with peace of mind. Later, at Policygenius, Tyler expanded the company’s reach into new products, turning Policygenius into an industry-leading distributor of disability and P&C insurance. Tyler’s efforts helped more than 10,000 people find the insurance they needed.',
    backgroundColor: colors.peach,
  },
  ian: {
    name: 'Ian Yamey',
    role: 'CTO',
    bio:
      'Ian has launched industry-leading consumer products as CTO of Policygenius and CTO of QuadPay. Ian has scaled three startups from 5 to 60+ employees and over $100m in funding. Ian is also the founder of Donational.org, an automated charitable advisor that helps individuals to make a greater impact in their charitable giving.',
    backgroundColor: colors.peach,
  },
};
